import { IImportProgress } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createImportApi(roseApi: RoseApi) {
  return {
    startImport: (incremental: boolean, scripts?: string) =>
      roseApi.collector.get<{ message: string }>(`/api/startimport/${incremental}`, { query: { scripts } }),
    importProgress: () =>
      roseApi.collector.get<IImportProgress>(`/api/importprogress`, { query: { includelast: true } }),
    getTerminbuchDebug: (behandler: string, tag: string) =>
      roseApi.collector.get<any>(`/api/terminbuch/${behandler}/${tag}`),
  };
}
