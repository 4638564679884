"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDoctosyncApi = void 0;
function createDoctosyncApi(roseApi) {
    return {
        getUnmatchedPatients: async () => roseApi.doctosync.get(`/api/unmatchedpatients`),
        addPatid: async (extid, patid) => roseApi.doctosync.post(`/api/addpatid`, { extid, patid }),
        reset: async () => roseApi.doctosync.post(`/api/reset`),
    };
}
exports.createDoctosyncApi = createDoctosyncApi;
