import {
  IFactoringClientRechenzentren,
  IFactoringClientRechenzentrumDB,
  IFactoringPraxis,
  IFactoringPraxisStempelInfo,
} from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { authStore } from '@/state/authStore';
import { factoringApi } from '@/services/data';
import { clientStateStore } from '@/state/clientStateStore';

export interface factoringStore {
  clientRZs: IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>;
  praxisDaten: IFactoringPraxis;
}

const factoringState: factoringStore = {
  clientRZs: {} as IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>,
  praxisDaten: {} as IFactoringPraxis,
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => factoringState,
  getters: {
    praxisStempelInfo(state): IFactoringPraxisStempelInfo | undefined {
      if (!state.praxisDaten?.infos) {
        return undefined;
      }
      return {
        stempelname1: state.praxisDaten.infos.stempelname1,
        stempelname2: state.praxisDaten.infos.stempelname2,
        stempelstrasse: state.praxisDaten.infos.stempelstrasse,
        stempelplz: state.praxisDaten.infos.stempelplz,
        stempelort: state.praxisDaten.infos.stempelort,
      };
    },
  },
  mutations: {
    setClientRZs(state, data: IFactoringClientRechenzentren<IFactoringClientRechenzentrumDB>) {
      state.clientRZs = data;
    },
    setPraxisDaten(state, data: IFactoringPraxis) {
      state.praxisDaten = data;
    },
  },
  actions: {
    async init(context) {
      const { dispatch } = rootActionContext(context);
      const hasR4c = authStore.getters.hasR4c || clientStateStore.getters.isFakeData;

      if (!hasR4c) {
        console.log('no r4c, skipping initialization of factoringStore');
        return;
      }
      await Promise.all([dispatch.initClientRechenzentren(), dispatch.initPraxisDaten()]);

      const { state } = rootActionContext(context);
      if (!state.clientRZs) {
        console.log('initializing factoringStore', state.clientRZs, state.praxisDaten);
      }
    },
    async initClientRechenzentren(context) {
      const { commit } = rootActionContext(context);

      if (!authStore.state.profile?.r4chost) {
        console.warn('no r4cHost, cannot initialize clientRechenzentren');
        return;
      }

      try {
        const clientRZs = await factoringApi.getClientRechenzentren(authStore.state.profile?.r4chost);

        commit.setClientRZs(clientRZs);
      } catch (error) {
        console.error('error initializing clientRechenzentren', error);
      }
    },
    async initPraxisDaten(context) {
      const { commit } = rootActionContext(context);

      const praxisDaten = await factoringApi.getPraxisDaten();
      commit.setPraxisDaten(praxisDaten);
    },
  },
});

export const factoringStore = store;
