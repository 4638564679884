import {
  ILeistungenQueryParams,
  ILeistungenTermineKommentarParams,
  ILeistungenTermineQueryParams,
  IOffeneGOZLeistungenExtended,
  Termine,
  ILeistungenWiedervorlageInfo,
  ILeistungTerminKommentar,
  IActionResult,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createLeistungenApi(api: RoseApi) {
  return {
    getLeistungen: (queryParams?: ILeistungenQueryParams) =>
      api.metrics.get<IOffeneGOZLeistungenExtended[]>(`/api/leistungen`, {
        query: queryParams,
      }),
    getLeistungenTermine: (queryParams?: ILeistungenTermineQueryParams) =>
      api.metrics.get<{
        patientKommentar?: ILeistungTerminKommentar;
        tagLeistungen: {
          sumHonorargoz: number;
          sumHonorargozoffen: number;
          tag: string;
          kommentar: ILeistungTerminKommentar;
          termine: { tagtermine: Termine[] };
        }[];
      }>(`/api/leistungen/termine`, {
        query: queryParams,
      }),
    saveTerminKommentar: (data?: ILeistungenTermineKommentarParams) =>
      api.metrics.post<IActionResult<void>>(`/api/leistungen/termine/kommentar`, data),
    saveLeistungWVLInfo: (data: ILeistungenWiedervorlageInfo) =>
      api.metrics.post(`/api/leistungen/wiedervorlage`, data),
  };
}
