import { sumBy, map, size, chain, each, reject, values } from 'lodash';
import { createEmptyWorkSheet, createCell, boldCellStyle, finalizeSimpleXLSXWithMetadata } from '../../../base';
import { ILeistungenSummaryRow, IOffeneGOZLeistungenExtended, ITeam, Units } from '../../../types';
import { createWorkBook, saveXLSX } from './exportExcel';
import { getValueFromGrid, getUnitFromGrid } from '../gridUtils';

export function commonCreateExtendedSummaryRow(
  leistungen: readonly IOffeneGOZLeistungenExtended[],
  summeryRowsConfig: { title: string | number; yearKey: string; filter: any; spezialFilter?: string[] }[],
  leistungsbehandler: null | ITeam,
): ILeistungenSummaryRow[] {
  let history = summeryRowsConfig.map(({ yearKey, title, filter, spezialFilter }) => {
    let leistungenYear: IOffeneGOZLeistungenExtended[] = map(leistungen, (l: any) => l.jahresVerteilung[yearKey]);
    return {
      isSummary: true,
      doFilter: filter,
      spezialFilter,
      isExtended: true,
      honorarerstattung: sumUpKey(leistungenYear, 'honorarerstattung'),
      honorar: sumUpKey(leistungenYear, 'honorar'),
      material: sumUpKey(leistungenYear, 'material'),
      eigenlabor: sumUpKey(leistungenYear, 'eigenlabor'),
      eigenlabormaterial: sumUpKey(leistungenYear, 'eigenlabormaterial'),
      fremdlabor: sumUpKey(leistungenYear, 'fremdlabor'),
      gesamt: sumUpKey(leistungenYear, 'gesamt'),
      count: chain(leistungenYear)
        .filter(l => l.gesamt > 0)
        .size()
        .value(),
      title: title,
    };
  });

  return [commonCreateSummaryRow(leistungen, leistungsbehandler), ...history];
}

function sumUpKey(list: readonly any[], key: string) {
  return sumBy(list, l => l[key]);
}

export function commonCreateSummaryRow(
  leistungen: readonly IOffeneGOZLeistungenExtended[],
  leistungsbehandler: null | ITeam,
): ILeistungenSummaryRow {
  let behandler: any = {};
  each(leistungen, l => {
    each(l.leistungsErbringerVerteilung, entry => {
      if (!behandler[entry.behandler]) {
        behandler[entry.behandler] = {
          ...entry,
          gesamt: entry.gesamt,
          honorar: entry.honorar,
        };
      } else {
        behandler[entry.behandler].gesamt += entry.gesamt;
        behandler[entry.behandler].honorar += entry.honorar;
      }
    });
  });
  let leistungsErbringerVerteilung = values(behandler);
  console.log('XXXXXXXXX', leistungen, leistungsErbringerVerteilung);
  let honorarLeistungserbringer = 0;
  if (leistungsbehandler) {
    honorarLeistungserbringer = chain(leistungen)
      .flatMap(l => l.leistungsErbringerVerteilung)
      .filter(lev => lev.behandler === leistungsbehandler.id)
      .sumBy(e => e.honorar)
      .value();
  }
  return {
    isSummary: true,
    isExtended: false,
    count: size(leistungen),
    title: 'SUMME',
    honorarerstattung: sumUpKey(leistungen, 'honorarerstattung'),
    honorar: sumUpKey(leistungen, 'honorar'),
    material: sumUpKey(leistungen, 'material'),
    eigenlabor: sumUpKey(leistungen, 'eigenlabor'),
    eigenlabormaterial: sumUpKey(leistungen, 'eigenlabormaterial'),
    fremdlabor: sumUpKey(leistungen, 'fremdlabor'),
    gesamt: sumUpKey(leistungen, 'gesamt'),
    leistungsErbringerVerteilung,
    honorarLeistungserbringer,
  };
}

export function downloadExcel(
  columnDefs: any[],
  rowData: IOffeneGOZLeistungenExtended[],
  patientSearchInput: string,
  subtractHkp: boolean,
  stammbehandler: ITeam,
  leistungsbehandler: ITeam,
  dateEnabled: boolean,
  from: string,
  to: string,
) {
  let wb = createWorkBook();
  let ws = createEmptyWorkSheet(wb, 'Leistungen');
  let exportColumns = reject(columnDefs, cd => cd.noexport);
  exportColumns.push(
    {
      headerName: 'Honorar-MwSt',
      field: 'honorarmwst',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-MwSt',
      field: 'eigenlabormwst',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-Material-MwSt',
      field: 'eigenlabormaterialmwst',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Fremdlabor-MwSt',
      field: 'fremdlabormwst',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Material-MwSt',
      field: 'materialmwstoffen',
      width: 85,
      exportUnit: Units.EURO,
    },
  );

  // write header
  let colCount = 0;
  let rowCount = 0;
  for (const cd of exportColumns) {
    createCell(ws, colCount++, rowCount, cd.headerName, Units.NONE, boldCellStyle);
  }

  // write data
  let sortedRowData = chain(rowData)
    .sortBy(r => r.name)
    .value();
  for (const row of sortedRowData) {
    colCount = 0;
    rowCount++;
    for (const cd of exportColumns) {
      createCell(ws, colCount++, rowCount, getValueFromGrid(row, cd), getUnitFromGrid(cd));
    }
  }

  let metaData: any = {
    Filter: patientSearchInput || '-',
    HKP: subtractHkp ? 'ohne' : 'mit',
    Stammbehandler: stammbehandler?.name || '-',
    Leistungerbringer: leistungsbehandler?.name || '-',
  };

  if (dateEnabled) {
    metaData['Datum-Filter'] = `${from} - ${to}`;
  }

  finalizeSimpleXLSXWithMetadata(
    wb,
    ws,
    metaData,
    colCount,
    rowCount,
    map(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width) })),
  );
  saveXLSX(wb, 'leistungen.xlsx');
}
